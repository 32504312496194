import React from "react";
import './Bank.css'
import { Link } from "react-router-dom";
import Header from "../../Header/Header";
import { currentProfile, requireLoggedIn, rerouteToLogin, updateProfile } from "../../services/Login";
import { Transaction, TransactionData, TransactionRequest, approveTransactionRequest, denyTransactionRequest, listTransactionRequests, listTransactions } from "../../services/Bank";

interface TransactionRequestConfirmation {
    data: TransactionData,
    isApproval: boolean,
    confirmationFailedReason?: string
}

function confirmationRequestRequiresPassword(request: TransactionRequestConfirmation): boolean {
    return request.isApproval && request.data.from !== currentProfile!!.dbusername
}

interface BankState {
    balance?: number
    transactions?: Transaction[]
    transactionRequests?: TransactionRequest[]
    transactionRequestConfirmation?: TransactionRequestConfirmation
}

export default class Bank extends React.Component<{}, BankState> {
    
    updateTransactions() {
        updateProfile().then(() => {
            this.setState({ ...this.state, balance: currentProfile?.bankBalance})
            listTransactions().then(transactions => {
                this.setState({ ...this.state, transactions })
                listTransactionRequests().then(transactionRequests => {
                    this.setState({ ...this.state, transactionRequests})
                })
            })
        })
    }

    componentDidMount() {
        if(!requireLoggedIn()) return
        this.updateTransactions()
    }

    renderTransactionData(data: TransactionData, showRequestApprovalAndDenyButton: boolean = false) {
        const isIncoming = data.to === currentProfile!!.dbusername
        return (<li key={data.id}>
            <div className="BankTransactionData">
                <svg width="1em" viewBox="0 0 10 10" className={isIncoming ? "BankIncomingArrow" : "BankOutgoingArrow"}>
                    <path fillRule='evenodd' clip-rule="evenodd" d="M 5 0 L 8 3 L 6 3 L 6 10 L 4 10 L 4 3 L 2 3 Z" fill={isIncoming ? "lime" : "red"}/>
                </svg>
                <span className={isIncoming ? "BankTransactionAmountPos" : "BankTransactionAmountNeg"}>
                    {isIncoming || "-"}{data.amount / 100}€
                </span>
                <span className={isIncoming ? "BankTransactionPayer" : "BankTransactionRecipient"}>
                    {isIncoming ? `Von: ${data.from}` : `An: ${data.to}`}
                </span>
                {data.description}
                <span className="BankTransactionTime">
                    {new Date(data.time * 1000).toLocaleString(undefined, {weekday: "long", hour:"2-digit","minute":"2-digit"})}
                </span>
                {showRequestApprovalAndDenyButton && <button className="BankApproveTransactionRequest" title="Approve" type="button" onClick={() => this.onApproveRequest(data)}>
                    <svg className="BankApproveDenyIcon" viewBox='0 0 10 10'>
                        <path d="M 4.25 8 L 1.75 5.5 L 2.75 4.5 L 4.25 6 L 7.25 3 L 8.25 4 Z" fill="black"/>
                    </svg>
                </button>}
                {showRequestApprovalAndDenyButton && <button className="BankDenyTransactionRequest" title="Deny" type="button" onClick={() => this.onDenyRequest(data)}>
                    <svg className="BankApproveDenyIcon" viewBox='0 0 10 10'>
                        <path d="M 5 4 L 7 2 L 8 3 L 6 5 L 8 7 L 7 8 L 5 6 L 3 8 L 2 7 L 4 5 L 2 3 L 3 2 Z" fill="black"/>
                    </svg>
                </button>}
            </div>
        </li>)
    }
    
    onApproveRequest(request: TransactionRequest) {
        this.setState({ ...this.state, transactionRequestConfirmation: { data: request, isApproval: true } })
    }

    onDenyRequest(request: TransactionRequest) {
        this.setState({ ...this.state, transactionRequestConfirmation: { data: request, isApproval: false } })
    }

    onConfirmApproveRequest(requestId: number, password?: string) {
        approveTransactionRequest(requestId, password).then(
            _ => ({transactionRequestConfirmation: undefined}),
            error => {
                let errorMessage: string = "Unbekannter Fehler"
                if(error.response && error.response.status === 400 && error.response.data === 'Insufficient funds') {
                     errorMessage = "Unzureichend Geld"
                } else if(error.response && error.response.status === 401) {
                    if(error.response.data === "du bist nicht angemeldet") {
                        rerouteToLogin()
                    } else if(error.response.data === "Unauthorized to approve this transaction") {
                        errorMessage = "Falsches Passwort"
                    }
                }
                return { transactionRequestConfirmation: {
                    ...this.state.transactionRequestConfirmation!!,
                    confirmationFailedReason: errorMessage
                } }
            }
        ).then(newState => this.setState({ ...this.state, ...newState})
        ).then(() => {
            this.updateTransactions()
        })
    }

    onConfirmDenyRequest(requestId: number) {
        denyTransactionRequest(requestId).then(() => {
            this.updateTransactions()
        })
    }

    render() {
        return (
        <div className="Bank">
            <Header goBackLink="/"/>
            <div className="BankContent">
                <div className="BankBalance">
                    Dein Kontostand: {(this.state?.balance || 0) / 100}
                </div>
                <div className="BankCreateTransactionDataLinkList">
                    <div className="BankCreateTransactionDataLink">
                        <Link to="createTransactionRequest">Überweisungsanfrage erstellen</Link>
                    </div>
                    <div className="BankCreateTransactionDataLink">
                        <Link to="createTransaction">Überweisung erstellen</Link>
                    </div>
                </div>
                <div className="BankTransactionDataList">
                    Deine Überweisungsanfragen:
                    <ul className="BankTransactionRequests">
                        {
                            this.state && (this.state.transactionRequests?.length === 0
                                ? <span key={-1}>Du hast noch keine Überweisungsanfragen</span>
                                : this.state.transactionRequests?.map(request =>
                                    this.renderTransactionData(request, true))
                            )
                        }
                    </ul>
                </div>
                <div className="BankTransactionDataList">
                    Deine Überweisungen:
                    <ul className="BankTransactions">
                        {
                            this.state && (this.state.transactions?.length === 0
                                ? <span key={-1}>Du hast noch keine Überweisungen</span>
                                : this.state.transactions?.map(transaction =>
                                    this.renderTransactionData(transaction, false))
                            )
                        }
                    </ul>
                </div>
            </div>
            { this.state?.transactionRequestConfirmation &&
                <div className="TransactionRequestApprovalConfirmation">
                    <div className="TransactionRequestApprovalConfirmationText">
                        Möchtest du folgende Überweisungsanfrage wirklich {this.state.transactionRequestConfirmation.isApproval ? "bestätigen" : "entfernen"}?
                        <div className="BankTransactionDataList">
                            <ul className="BankTransactionRequests">
                                {this.renderTransactionData(this.state.transactionRequestConfirmation.data)}
                            </ul>
                        </div>
                    </div>
                    {
                        confirmationRequestRequiresPassword(this.state.transactionRequestConfirmation) &&
                            <div className="TransactionRequestApprovalPassword">
                                <div>
                                    <input type="password" placeholder="Passwort" id="TransactionRequestApprovalPassword"/>
                                </div>
                                <div>
                                    <input type='checkbox' id='showPassword' onClick={event => {
                                        (document.getElementById("TransactionRequestApprovalPassword") as HTMLInputElement).type = (event.target as HTMLInputElement).checked ? 'text' : 'password'
                                    }}/>
                                    <label htmlFor='showPassword'>Passwort anzeigen</label>
                                </div>
                                <div>
                                {
                                    this.state.transactionRequestConfirmation.confirmationFailedReason &&
                                        <span className="TransactionRequestConfirmationFailedReason">
                                            {this.state.transactionRequestConfirmation.confirmationFailedReason}
                                        </span>
                                }    
                                </div>
                            </div>
                    }
                    <div className="TransactionRequestApprovalConfirmationButtons">
                        <button onClick={() => this.setState({ ...this.state, transactionRequestConfirmation: undefined })}>
                            Nein
                        </button>
                        <button onClick={() => {
                            if(this.state.transactionRequestConfirmation!!.isApproval) {
                                this.onConfirmApproveRequest(
                                    this.state.transactionRequestConfirmation!!.data.id,
                                    confirmationRequestRequiresPassword(this.state.transactionRequestConfirmation!!)
                                        ? (document.getElementById("TransactionRequestApprovalPassword") as HTMLInputElement).value
                                        : undefined
                                )
                            } else {
                                this.onConfirmDenyRequest(this.state.transactionRequestConfirmation!!.data.id)
                                this.setState({ ...this.state, transactionRequestConfirmation: undefined })
                            }
                        }}>
                            Ja
                        </button>
                    </div>
                </div>
            }
        </div>)
    }
}
