import React from "react";
import './CreateTransaction.css'
import Header from "../../Header/Header";
import { requireLoggedIn } from "../../services/Login";
import UsernameInput from "../Login/UsernameInput";
import { addTransaction, requestTransaction } from "../../services/Bank";
import { AxiosResponse } from "axios";
import { Navigate } from "react-router-dom";

export default class CreateTransactionData extends React.Component<{isRequest: boolean}, {notCreatedReason?: string, transactionDone?: boolean}> {

    componentDidMount() {
        if(!requireLoggedIn()) return
    }

    onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()

        const form = document.getElementById('TransactionForm') as HTMLFormElement
        const data = new FormData(form)

        const user = data.get('username') as string
        const amount = data.get('amount') as string
        const description = data.get('description') as string

        if(!user) {
            this.setState({...this.state, notCreatedReason: "Benutzername nicht ausgefüllt"})
            return
        }

        if(!amount) {
            this.setState({...this.state, notCreatedReason: "Betrag nicht ausgefüllt"})
            return
        }
        
        const parsedAmount = parseFloat(amount) * 100

        if(!Number.isFinite(parsedAmount) || parsedAmount <= 0 || parsedAmount % 1 !== 0) {
            this.setState({...this.state, notCreatedReason: "Ungültiger Betrag"})
            return
        }

        form.reset()

        if(this.props.isRequest) {
            this.handleTransactionResponse(requestTransaction(user, parsedAmount, description))
            return
        }
        this.handleTransactionResponse(addTransaction(user, parsedAmount, description))
    }

    handleTransactionResponse(response: Promise<AxiosResponse<any, any>>) {
        response.then(
            _ => ({transactionDone: true}),
            error => {
                let errorMessage: string = "Unbekannter Fehler"
                if(error.response && error.response.status === 400) {
                    switch(error.response.data) {
                        case 'Unknown username':
                        case 'Invalid username':
                            errorMessage = "Unbekannter Benutzername"
                            break
                        case 'Invalid description':
                            errorMessage = "Ungültiges Zeichen in Beschreibung"
                            break
                        case 'Invalid amount':
                            errorMessage = "Ungültige Anzahl"
                            break
                        case 'Insufficient funds':
                            errorMessage = "Unzureichend Geld"
                            break
                    }
                }
                return { notCreatedReason: errorMessage }
            }
        ).then(newState => this.setState({ ...this.state, ...newState}))
    }

    render() {
        const transactionTypeName = this.props.isRequest ? "Überweisungsanfrage" : "Überweisung"
        return (
            <div className="BankCreateTransactionData">
                {this.state && this.state.transactionDone && <Navigate to="/bank"/>}
                <Header goBackLink="/bank"/>
                <div className='TransactionFormContainer'>
                    <form className='TransactionForm' id='TransactionForm' onSubmit={event => this.onSubmit(event)}>
                        <div className='CreateTransactionTitle'>{transactionTypeName}</div>
                        <div className='FormElement'>
                            <div>
                                <label htmlFor='username'>{this.props.isRequest ? "Zahler" : "Empfänger"}:</label>
                            </div>
                            <div>
                                <UsernameInput/>
                            </div>
                        </div>
                        <div className='FormElement'>
                            <div>
                                <label htmlFor='transactionAmount'>Betrag / €:</label>
                            </div>
                            <div>
                                <input placeholder="1" name='amount' id='transactionAmount' type='number' required={true} className='textInput' min='0.01' step="0.01"/>
                            </div>
                        </div>
                        <div className='FormElement'>
                            <div>
                                <label htmlFor="transactionDescription">Verwendungszweck:</label>
                            </div>
                            <div>
                                <input name='description' id="transactionDescription" type='text' className='textInput' />
                            </div>
                        </div>
                        {
                            this.state && this.state.notCreatedReason && <p className='TransactionNotCreatedReason'>{this.state.notCreatedReason}</p>
                        }
                        <div className='CenteredButtonContainer'>
                            <button type="submit" className="Login">{transactionTypeName} erstellen</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}