import React from "react";
import './Header.css'
import { Link } from "react-router-dom";
import Header from "../../../Header/Header";

export default class InfoPageHeader extends React.Component {
    render() {
        return (
            <div className="InfoPageHeader">
                <Header goBackLink='/info'/>
            </div>
        )
    }
}