import React, { FormEvent, ReactNode, useEffect } from 'react';
import './Login.css';
import Header from '../../Header/Header';
import { loggedIn, login } from '../../services/Login';
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import UsernameInput from './UsernameInput';

interface LoginState {
  showPassword: boolean,
  redirect: boolean,
  unauthorizedReason: string
}

export default class Login extends React.Component<{}, LoginState> {

  showPasswordUpdated(event: React.MouseEvent<HTMLInputElement, MouseEvent>) {
    this.setState({ ...this.state, showPassword: event.currentTarget.checked})
  }
  
  onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()

    const form = document.getElementById('LoginForm') as HTMLFormElement
    const data = new FormData(form)
    form.reset()
    login(data.get('username') as string, data.get('password') as string).then(loginResult => {
      if(loginResult.result === "unauthorized") {
        this.setState({ ...this.state, unauthorizedReason: loginResult.reason })
        return
      }
      this.setState({ ...this.state, redirect: true })
    })
  }

  render(): ReactNode {
    return (
      <div className='Login'>
        { ((this.state && this.state.redirect) || loggedIn()) && <NavigateToRedirect />}
        <Header goBackLink='/'/>
        <div className='LoginFormContainer'>
          <form className='LoginForm' id='LoginForm' onSubmit={event => this.onSubmit(event)}>
            <div className='LoginTitle'>Anmelden</div>
            <div className='LoginExplanationWrapper'>
              <span className="LoginExplanation">Gebe hier deine Schule-als-Staat Zugangsdaten ein. Diese findest du in einer Datei auf Nextcloud, wie <Link to="guide" className='inline-link'>hier</Link> beschrieben. Du kannst dich hier <i>nicht</i> mit deinen Nextcloud Zugangsdaten direkt anmelden.</span>
            </div>
            <div className='FormElement'>
              <div>
                <UsernameInput />
              </div>
            </div>
            <div className='FormElement'>
              <div>
                <input placeholder='Passwort' type={this.state && this.state.showPassword ? 'text' : 'password'} name='password'/>
              </div>
              <div className='ShowPasswordContainer'>
                <input type='checkbox' id='showPassword' onClick={(event) => this.showPasswordUpdated(event)}/>
                <label htmlFor='showPassword'>Passwort anzeigen</label>
              </div>
            </div >
            {
              this.state && this.state.unauthorizedReason && <p className='LoginUnauthorizedReason'>{this.state.unauthorizedReason}</p>
            }
            <div className='CenteredButtonContainer'>
              <button type="submit" className="Login">Anmelden</button>
            </div>
            <div className='CenteredButtonContainer'>
              <Link to='guide' className='GuideLink button-inline-link'>Wo bekomme ich meine Zugangsdaten?</Link>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function NavigateToRedirect() {
  const [searchParams] = useSearchParams()
  const redirect = searchParams.get('redirect')
  const navigate = useNavigate()
  useEffect(() => {
    navigate(redirect || '/')
  })
  return null
}